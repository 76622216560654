export const currencyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const integerFormatter = new Intl.NumberFormat("pt-BR", {
  style: "decimal",
  minimumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 2,
  style: "decimal",
});

export const weightFormatter = new Intl.NumberFormat("pt-BR", {
  minimumFractionDigits: 3,
  style: "decimal",
});

export const dateFormatter = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (date && !Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", { dateStyle: "short" }).format(
        dateModel
      );
    else return "";
  },
};

export const currencyFormatterBlankZero = {
  format(v) {
    if (v) return currencyFormatter.format(v);
    else return "";
  },
};

export const dateFormatterAPI = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (dateModel.getTime()) return dateModel.toISOString().split("T")?.[0];
    else return "";
  },
};

export const dateFormatterLong = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (!Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "long",
        timeStyle: "short",
      }).format(dateModel);
    else return "";
  },
};

export const timeFormatter = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (!Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(dateModel);
    else return "";
  },
};

export const dateFormatterShort = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (!Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }).format(dateModel);
    else return "";
  },
};

export const dateFormatterMonthYear = {
  format: (date) => {
    let dateModel;
    if (typeof date === "string") dateModel = new Date(date);
    else dateModel = date;

    if (!Number.isNaN(dateModel.getTime()))
      return new Intl.DateTimeFormat("pt-BR", {
        month: "short",
        year: "numeric",
      }).format(dateModel);
    else return "";
  },
};

export const graphLocales = () => [
  {
    name: "pt-br",
    options: {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      days: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      toolbar: {
        show: false,
      },
    },
  },
];
