import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { dateFormatterShort } from "../utils/formatters";

export const generatePDF = ({
  fileName = "Relatório.pdf",
  headerMessage = "Relatório",
  isMultipleTables = false,
  addTableTitle = false,
  addTableSummary = false,
  tableMessages,
  defaultFontSize = 5,
  orientation = "portrait",
  headers,
  marginTop,
  content,
  textHeader,
  textFooter,
}) => {
  const printedMessage = `Impresso em ${dateFormatterShort.format(new Date())}`;
  const marginLeft = 15;

  const doc = new jsPDF({ compress: true, orientation });

  const tables = isMultipleTables ? content : [content];

  tables.forEach((table, id) => {
    if (addTableTitle) {
      doc.setFontSize(12);
      var finalY = doc.lastAutoTable.finalY || 0
      doc.text(textHeader || tableMessages?.[id] || '', 15, finalY + 15);
    }

    doc.autoTable({
      head: headers,
      body: table,
      startY: (finalY || 0) + 20,
      showHead: true,
      showFoot: true,
      pageBreak: "auto",
      styles: {
        fontSize: defaultFontSize,
      },
      headStyles: { fillColor: [128, 128, 128] },
      columnStyles: {
        4: { minCellWidth: 20 },
        5: { minCellWidth: 20 },
        6: { minCellWidth: 20 },
      },
      margin: { top: marginTop || 30 },
    });

    const emptyIndex = doc.internal.pages.indexOf(p => !p);
    
    if (!isMultipleTables && emptyIndex !== -1)
      doc.deletePage(emptyIndex);
    

    if (addTableSummary) {
      doc.setFontSize(12);
      //doc.text(headerMessage, marginLeft, 50);
    }
  });

  addFooter(doc, marginLeft, textFooter);
  addHeader(doc, marginLeft, headerMessage, printedMessage);

  doc.save(fileName);
};

const addFooter = (doc, marginLeft, text) => {
  const pageSize = doc.internal.pageSize;
  const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

  if(text){
    doc.setPage(1);
    doc.setFontSize(12);
    doc.text(text, marginLeft, pageHeight - 35);
  }else{
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(5);

    for (let p = 1; p <= pageCount; p++) {
      doc.setPage(p);
      doc.text("Página " + p, marginLeft, pageHeight - 10);
    }
  }
};

// const addMargin = (doc) => {
//   const pageCount = doc.internal.getNumberOfPages();

//   doc.setLineWidth(1);

//   for (let p = 1; p < pageCount; p++) {
//     doc.setPage(p);
//     doc.lines([
//       [15, 15],
//       [15, 85],
//       [85, 15],
//       [195, 15],
//       [15, 15],
//     ]);
//     doc.line(20, 20, 60, 20); // horizontal line

//   }
// };

const addHeader = (doc, marginLeft, headerMessage, printedMessage) => {
  const pageCount = doc.internal.getNumberOfPages();

  doc.setTextColor(40);

  for (let p = 1; p <= pageCount; p++) {
    doc.setPage(p);

    doc.addImage(
      document.querySelector(".el-header img"),
      "PNG",
      marginLeft,
      5,
      30,
      5
    );
    doc.setFontSize(5);
    doc.text(printedMessage, marginLeft + 157, 10);
    doc.setFontSize(12);
    doc.text(headerMessage, marginLeft + 70, 10);
  }
};
